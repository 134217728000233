<template>
	<div>
		<div class="md:flex md:items-center md:justify-between mb-5">
			<div class="flex-1 min-w-0">
				<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
					Accordions
				</h2>
			</div>
			<div class="mt-4 flex md:mt-0 md:ml-4">
				<RefreshTableButton @refreshTable="refreshTable" />
				<router-link
					:to="{ name: 'content-accordions-create' }"
					class="relative ml-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
				>
					<PlusIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
					Add Accordion
				</router-link>
			</div>
		</div>

		<div class="flex flex-col">
			<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
					<ag-grid-vue
						style="height: 725px"
						class="ag-theme-alpine"
						:grid-options="gridOptions"
						:column-defs="columnDefs"
						:default-col-def="defaultColDef"
						:row-data="rowData"
						:modules="modules"
						:pagination="true"
						:pagination-page-size="50"
						:enable-cell-text-selection="true"
						:ensure-dom-order="true"
					></ag-grid-vue>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'

import { AgGridVue } from '@ag-grid-community/vue3'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import TableActionsById from '@/components/TableActionsById.vue'

import { PlusIcon } from '@heroicons/vue/solid'

export default {
	name: 'Services',
	components: {
		AgGridVue,
		PlusIcon,
	},
	setup() {
		const api = inject('api')
		const toast = useToast()

		return {
			api,
			toast,
		}
	},
	data() {
		return {
			columnDefs: null,
			rowData: null,
			defaultColDef: null,

			modules: [ClientSideRowModelModule],
		}
	},
	beforeMount() {
		this.gridOptions = {
			context: {
				componentParent: this,
			},
		}

		this.columnDefs = [
			// { field: "id", minWidth: 310 },
			{
				headerName: 'Name',
				field: 'name',
				flex: 1,
			},
			{
				headerName: 'Created At',
				field: 'created_at',
				flex: 1,
			},
			{
				headerName: 'Updated At',
				field: 'updated_at',
				flex: 1,
			},
			{
				headerName: 'Actions',
				width: 300,
				cellRendererFramework: TableActionsById,
				cellRendererParams: {
					editRoute: 'content-accordions-id',
					showEdit: true,
					showClone: true,
					showDelete: true,
					cloneRoute: 'content-accordions-create',
					deleteApi: 'accordions',
					deleteTitle: 'Delete Accordion',
					deleteContent: 'Are you sure you want to delete this Accordion?',
					deleteRoute: 'content-accordions',
					appApi: this.api,
					parent: this,
					newTab: true,
				},
			},
		]

		this.defaultColDef = {
			resizable: true,
			sortable: true,
			filter: 'agTextColumnFilter',
			floatingFilter: true,
		}
	},
	mounted() {
		this.gridApi = this.gridOptions.api
		this.fetchServices()
	},
	methods: {
		fetchServices(reload = false) {
			if (reload) {
				this.gridApi.showLoadingOverlay()
			}
			this.loaderShow()

			this.api.accordions
				.all()
				.then(data => {
					this.rowData = data
				})
				.catch(error => {
					this.toast.error('Error fetching accordions:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
					if (reload) {
						this.gridApi.hideOverlay()
					}
				})
		},

		refreshTable() {
			this.fetchServices(true)
		},

		booleanFormatter(params) {
			return params.value == '0' ? 'No' : 'Yes'
		},
	},
}
</script>
